<template>
    <div v-if="tenantDetails">
        <ellipsis-dropdown
            componentName="edit-tenant"
            :showDeleteBtn="curTenantId !== undefined"
            @save="onEdit"
            @delete="onDelete"
            :itemId="curTenantId"
        />
        <div class="d-inline-flex justify-content-start align-items-center mb-1 ml-50">
            <span @click="$router.push({name:'tenants'})" class="cursor-pointer d-flex align-items-center">
                <RtIcon icon="chevron-left" variant="light" :size="20" />
            </span>
            <h3 class="ml-1 mb-0">{{ i18nT(`Fiche locataire`) }}</h3>
        </div>
        <b-row>
            <b-col 
                cols="6"
            >
                <b-card
                    class="section-container"
                    no-body
                >
                    <b-card-header>
                        <h4 class="card-title">{{ i18nT(`Informations locataire`) }}</h4>
                        <!-- <b-dropdown
                            variant="link"
                            toggle-class="p-0"
                            no-caret
                            right
                        >
                            <template #button-content>
                                <RtIcon variant="light" icon="ellipsis-vertical text-body" :size="18"/>
                            </template>
                            <b-dropdown-item
                                v-if="tenantDetails.TenantID"
                                :to="{ name: 'edit-tenant', params: { id: tenantDetails.TenantID },}"
                            >
                                <span class="align-middle ml-50">{{i18nT(`Edit`)}}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="onDelete">
                                <span class="align-middle ml-50 text-danger">{{i18nT(`Delete`)}}</span>
                            </b-dropdown-item>
                        </b-dropdown> -->
                    </b-card-header>

                    <b-card-body>
                        <b-row>
                            <b-col cols="12">
                                <div
                                    class="d-flex w-100"
                                >
                                    <b-avatar
                                        :src="tenantDetails.image_url"
                                        :text="(tenantDetails.TenantFirstName ? tenantDetails.TenantFirstName.charAt(0) : '') + (tenantDetails.TenantLastName ? tenantDetails.TenantLastName.charAt(0) : '')"
                                        class="badge-light-danger mr-1"
                                        size="8rem"
                                        :style="{border: '2px solid ' + '#' + tenantDetails.TenantAvatarHexColor}"
                                    >
                                    </b-avatar>
                                    <div class="flex-grow-1 ml-1">
                                        <h3>{{ tenantDetails.TenantFullName }}</h3>
                                        <div class="info-item mt-50 text-truncate">
                                            <RtIcon variant="light" icon="user"/>
                                            <span
                                                v-if="tenantDetails.TenantBirthDate || tenantDetails.TenantBirthPlace"
                                                class="pl-1 position-relative text-primary"
                                            >
                                                {{ tenantDetails.TenantBirthDate ? tenantDetails.TenantBirthDate : "" }}
                                                {{ tenantDetails.TenantBirthDate && tenantDetails.TenantBirthPlace ? " / " : ""}}
                                                {{ tenantDetails.TenantBirthPlace ? tenantDetails.TenantBirthPlace + " " : "" }}
                                            </span>
                                            <span v-else class="pl-1">{{i18nT(`Pas disponible`)}}</span>
                                        </div>
                                        <div class="info-item mt-50 text-truncate">
                                            <RtIcon variant="light" icon="phone"/>
                                            <b-link
                                                v-if="tenantDetails.TenantMobilePhone"
                                                :href="'tel:'+tenantDetails.TenantMobilePhone"
                                                class="position-relative pl-1"
                                            >{{ tenantDetails.TenantMobilePhone}}</b-link>

                                            <span v-else class="pl-1">{{i18nT(`Pas disponible`)}}</span>
                                        </div>
                                        <div class="info-item mt-50 text-truncate">
                                            <RtIcon variant="light" icon="envelope"/>
                                            <b-link
                                                v-if="tenantDetails.TenantEmail"
                                                :href="'mailto:'+tenantDetails.TenantEmail"
                                                class="position-relative pl-1"
                                            >{{ tenantDetails.TenantEmail}}</b-link>

                                            <span v-else class="pl-1">{{i18nT(`Pas disponible`)}}</span>
                                        </div>
                                        <div class="info-item mt-50 text-truncate d-flex align-items-center">
                                            <RtIcon variant="light" icon="key"/>
                                            <div class="ren-status-badge d-flex pl-1 ">
                                                <b-badge
                                                    :variant="statusLabelClass(tenantDetails.StatusCode)"
                                                    v-b-tooltip = "getTooltipLabel(tenantDetails.StatusCode)"
                                                    class="mr-1 badge-pill"
                                                    :class="{'cursor-pointer': tenantDetails.StatusCode == 2 }"
                                                >
                                                  {{ i18nT(tenantDetails.StatusText)}}
                                                </b-badge>
                                                <b-badge
                                                    v-if="tenantDetails.TenantInvited && !tenantDetails.TenantStatus"
                                                    variant="primary"
                                                    class="badge-pill"
                                                >
                                                  {{i18nT(`Réinviter`)}}
                                                </b-badge>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                        <hr/>
                        <b-row>
                            <b-col cols="6">
                                <h4  class="mt-1">{{ i18nT(`Bien loué`)}} </h4>
                                <hr/>
                                <b-row v-if="rentedProperties && rentedProperties.length > 0">
                                    <b-col
                                        cols="12"
                                        class="ren-info-cards"
                                        v-for="(rental, index) in rentedProperties"
                                        :key="index + 'rental'"
                                    >
                                        <p class="m-0"><strong>{{ rental.PropertyTitle }}</strong></p>
                                        <p class="m-0">{{ rental.PropertyAddress }}</p>
                                        <p class="m-0">{{ rental.PropertyPostalCode }}</p>
                                        <p class="m-0">{{ rental.PropertyCity }}</p>
                                        <p class="m-0">{{ rental.PropertyCountry }}</p>
                                    </b-col>
                                </b-row>
                                <b-row v-else>
                                    <b-col 
                                        cols=12 
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="6">
                                <h4  class="mt-1">{{ i18nT(`Adresse du locataire`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col 
                                        cols="12" 
                                        v-if="tenantDetails.TenantAddress1"
                                        class="ren-info-cards"
                                    >
                                        <p class="m-0">{{ tenantDetails.TenantAddress1 }} {{ tenantDetails.TenantAddress2}}</p>
                                        <p class="m-0">{{ tenantDetails.TenantCity}}</p>
                                        <p class="m-0">{{  tenantDetails.TenantZip }}</p>
                                        <p class="m-0">{{ tenantDetails.TenantCountry }}</p>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="6">
                                <h4 class="mw-75">{{ i18nT(`Situation professionnelle`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col 
                                        cols="12" 
                                        v-if="tenantDetails.TenantProfession"
                                        class="ren-info-cards"
                                    >
                                        <p class="m-0" v-if="tenantDetails.TenantProfession">
                                            <strong>{{ i18nT(`Profession locataire:`) }}</strong> {{ tenantDetails.TenantProfession}}
                                        </p>
                                        <p class="m-0" v-if="tenantDetails.TenantIncome">
                                            <strong>{{ i18nT(`Revenus:`) }}</strong> {{ tenantDetails.TenantIncome}}
                                        </p>
                                        <p class="m-0" v-if="tenantDetails.TenantSituationProf"> 
                                            <strong>{{i18nT(`Situation professionnelle:`)}}</strong> {{ tenantDetails.TenantSituationProf }}
                                        </p>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="6">
                                <h4 class="mw-75">{{ i18nT(`Adresse professionnelle`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col 
                                        cols="12" 
                                        v-if="tenantDetails.TenantProEmployer"
                                        class="ren-info-cards"
                                    >
                                        <p class="m-0" v-if="tenantDetails.TenantProEmployer">
                                            <strong>{{ i18nT(`Employeur:`) }}</strong> {{ tenantDetails.TenantProEmployer}}
                                        </p>
                                        <p class="m-0" v-if="tenantDetails.TenantProPhone">
                                            <strong>{{ i18nT(`Phone:`) }}</strong> {{ tenantDetails.TenantProPhone}}
                                        </p>
                                        <p class="m-0" v-if="tenantDetails.TenantProState"> 
                                            <strong>{{i18nT(`State:`)}}</strong> {{ tenantDetails.TenantProState }}
                                        </p>
                                        <p class="m-0" v-if="tenantDetails.TenantProZip"> 
                                            <strong>{{i18nT(`Zip:`)}}</strong> {{ tenantDetails.TenantProZip }}
                                        </p>
                                        <p class="m-0" v-if="tenantDetails.TenantProCountry"> 
                                            <strong>{{i18nT(`Country:`)}}</strong> {{ tenantDetails.TenantProCountry }}
                                        </p>
                                        <p class="m-0" v-if="tenantDetails.TenantProCity"> 
                                            <strong>{{i18nT(`City:`)}}</strong> {{ tenantDetails.TenantProCity }}
                                        </p>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <h4>{{ i18nT(`Banque`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col 
                                        cols="12" 
                                        v-if="tenantDetails.TenantBankCode"
                                        class="ren-info-cards"
                                    >
                                        <p class="m-0" v-if="tenantDetails.TenantBankCode">
                                            <strong>{{ i18nT(`Code banque`) }}</strong> {{ tenantDetails.TenantBankCode}}
                                        </p>
                                        <p class="m-0" v-if="tenantDetails.TenantBankBranchCode">
                                            <strong>{{ i18nT(`Code guichet`) }}</strong> {{ tenantDetails.TenantBankBranchCode}}
                                        </p>
                                        <p class="m-0" v-if="tenantDetails.TenantBankName"> 
                                            <strong>{{i18nT(`Banque`)}}</strong> {{ tenantDetails.TenantBankName }}
                                        </p>
                                        <p class="m-0" v-if="tenantDetails.TenantBankIBAN"> 
                                            <strong>{{i18nT(`IBAN`)}}</strong> {{ tenantDetails.TenantBankIBAN }}
                                        </p>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <h4>{{ i18nT(`Notes`)}} </h4>
                                <hr/>
                                <b-row>
                                    <b-col 
                                        cols="12" 
                                        v-if="tenantDetails.TenantAdminNotes || tenantDetails.TenantNotes"
                                        class="ren-info-cards"
                                    >
                                        <p class="m-0" v-if="tenantDetails.TenantAdminNotes">
                                            <strong>{{ i18nT(`My notes:`) }}</strong> {{ tenantDetails.TenantAdminNotes}}
                                        </p>
                                        <p class="m-0" v-if="tenantDetails.TenantNotes">
                                            <strong>{{ i18nT(`Tenant notes: `) }}</strong> {{ tenantDetails.TenantNotes}}
                                        </p>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-else
                                        class="ren-info-cards"
                                    >
                                        <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <h4>{{ i18nT(`Garants`)}} </h4>
                                <hr/>
                                <b-row v-if="guarantors && guarantors.length > 0">
                                    <b-col
                                        cols="12"
                                        v-for="(guarantor, index) in guarantors"
                                        :key="'contact ' + index"
                                    >
                                      <contact-card  :contact="guarantor"/>

                                    </b-col>
                                </b-row>
                                <b-row v-else>
                                    <b-col
                                        cols="12"
                                        class="ren-info-cards"
                                    >
                                      <p>{{ i18nT(`Pas d'information`) }}</p>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <h4>{{ i18nT(`Contacts d'urgence`)}} </h4>
                                <hr/>
                                <b-row v-if="contacts && contacts.length > 0">
                                    <b-col
                                        cols="12"
                                        v-for="(contact, index) in contacts"
                                        :key="'contact ' + index"
                                        >
                                            <contact-card  :contact="contact"/>
                                    </b-col>
                                </b-row>
                                <b-row v-else>
                                    <b-col
                                        cols="12"
                                        class="ren-info-cards"
                                    >
                                    <p>{{ i18nT(`Pas d'information`) }}</p>
                                </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                        <b-row v-if="statusLabel(tenantDetails.TenantStatus, tenantDetails.TenantInvited, tenantDetails.TenantEmail) === i18nT(`Inviter`) ||  
                            statusLabel(tenantDetails.TenantStatus, tenantDetails.TenantInvited, tenantDetails.TenantEmail) === i18nT(`En attente`)">
                            <b-col cols="12">
                                <h4>{{ i18nT(`Lien d'invitation`) }}</h4>
                                <hr>
                                <b-input-group>
                                    <b-input-group-prepend class="cursor-pointer" is-text @click="copyLink" v-b-tooltip="i18nT(`Sélectionnez le lien`)">
                                        <RtIcon variant="light" icon="link"/>
                                    </b-input-group-prepend>
                                    <b-form-input
                                        :value="activationLink"
                                        disabled
                                    >
                                    </b-form-input>
                                    <b-input-group-append class="cursor-pointer" is-text @click="openLink"  v-b-tooltip="i18nT(`Ouvrir le lien`)">
                                        <RtIcon variant="light" icon="up-right-from-square"/>
                                    </b-input-group-append>
                                </b-input-group>
                                <p class="pt-1">{{ i18nT(`Inviter le locataire. Le locataire aura accès uniquement aux informations liées à sa location, ses quittances de loyer et il pourra vous envoyer des messages via son interface.`) }}</p>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col 
                cols="6"
            >
                <b-tabs
                    pills
                >
                    <b-tab
                        :title="i18nT(`Finances`)"
                    >
                    <template #title>
                        <div class="d-flex align-items-center ren-title">
                            <div class="mr-50">
                                <RtIcon :size="18" variant="light" icon="coins"/>
                            </div>
                            <p class="m-0">
                                {{ i18nT(`Finances`)}}
                            </p>
                        </div>
                    </template>
                        <b-card
                            no-body
                        >
                            <b-card-header class="pb-0">
                                <h4>{{ i18nT(`Finances`) }}</h4>
                            </b-card-header>
                            <b-card-body>
                                <b-row> 
                                    <b-col
                                        cols="12"
                                    >
                                        <hr class="mt-0 mb-2"/>
                                    </b-col>
                                    <b-col
                                        cols="5"
                                    >   
                                        <b-card no-body class="ren-default-card">
                                            <b-card-body class="px-1 py-1">
                                                <h6>{{ i18nT(`Revenus`) }}</h6>
                                                <h3 
                                                    class="m-0"
                                                    :class="tenantDetails.TenantIncome >= 0 ? 'text-primary' : 'text-danger'"
                                                >
                                                    {{ {amount: parseInt(tenantDetails.TenantIncome)} | currency }}
                                                </h3>
                                            </b-card-body>
                                        </b-card>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                    >
                                        <b-card no-body class="ren-default-card">
                                            <b-card-body class="px-1 py-1 d-flex align-items-center">
                                                <div class="d-flex justify-content-center align-items-center mr-2">
                                                    <b-avatar size="6em" variant="light-warning">
                                                        <RtIcon variant="thin" icon="coins" :size="37"/>
                                                    </b-avatar>
                                                </div>
                                                <div>
                                                    <h6>{{ i18nT(`Solde locataire`) }}</h6>
                                                    <h1 
                                                        class="m-0"
                                                        :class="tenantDetails.TenantBalance  >= 0 ? 'text-primary' : 'text-danger'"
                                                    >
                                                        {{ {amount: parseInt(tenantDetails.TenantBalance)} | currency }}
                                                    </h1>
                                                </div>
                                            </b-card-body>
                                        </b-card>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                    >
                                        <div class="mt-1 d-flex justify-content-around">
                                            <b-button class="w-100 mr-1" variant="outline-primary">{{ i18nT(`Finances`)}}</b-button>
                                            <b-button class="w-100" variant="outline-primary">{{ i18nT(`Détail du solde`)}}</b-button>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </b-tab>
                    <b-tab v-if="rentedProperties && rentedProperties.length > 0">
                        <template #title>
                            <div class="d-flex align-items-center ren-title">
                                <div class="mr-50">
                                    <RtIcon :size="18" variant="light" icon="key"/>
                                </div>
                                <p class="m-0">
                                    {{ i18nT(`Baux`)}}
                                </p>
                            </div>
                        </template>
                        <b-card
                            no-body
                        >
                            <b-card-header class="pb-0">
                                <h4>{{ i18nT(`Baux`) }}</h4>
                            </b-card-header>
                            <b-card-body>
                                <b-row>
                                    <b-col
                                        cols="12"
                                    >
                                        <hr class="mt-0 mb-2"/>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-for="(rentals, index) in rentedProperties"
                                        :key="'rentals' + index"
                                    >
                                        <b-card no-body border-variant="light">
                                            <b-card-body>
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-flex flex-column justify-content-between ">
                                                        <b-link>{{ rentals.PropertyTitle }}</b-link>
                                                        <span>{{ rentals.PropertyAddress }} {{ rentals.PropertyPostalCode }} {{ rentals.PropertyCity }}</span>
                                                        <p class="m-0">{{ rentals.LeaseType }}</p>
                                                        <p class="m-0">{{ rentals.LeaseStartDate | date }} - {{ rentals.LeaseEndDate | date }}</p>
                                                        <p class="m-0">{{ i18nT(`Loyer  `) }}
                                                            <span class="text-primary">{{ {amount: rentals.LeaseMonthlyAmount} | currency }}</span>
                                                        </p>
                                                        <p class="m-0">{{ i18nT(`Charges  `) }}
                                                            <span class="text-primary">{{ {amount: rentals.LeaseMaintenance} | currency }}</span>
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <span class="badge badge-primary" v-if="rentals.LeaseActive === '1'">{{ i18nT(`ACTIVE`)}}</span>
                                                        <span class="badge badge-warning" v-else>{{ i18nT(`INACTIF`)}}</span>
                                                    </div>
                                                </div>
                                            </b-card-body>
                                        </b-card>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-button class="w-100" variant="outline-primary">{{ i18nT(`Tout afficher`) }}</b-button>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </b-tab>
                    <b-tab v-if="tasks && tasks.length">
                        <template #title>
                            <div class="d-flex align-items-center ren-title">
                                <div class="mr-50">
                                    <RtIcon :size="18" variant="light" icon="clipboard-check"/>
                                </div>
                                <div>
                                    {{ i18nT(`Tâches`)}}
                                    <b-badge v-if="tasks.length > 0" variant="primary">{{tasks.length}}</b-badge>
                                </div>
                            </div>
                        </template>
                        <b-card
                            no-body
                        >
                            <b-card-header class="pb-0">
                                <h4>{{ i18nT(`Tâches en cours`) }}</h4>
                            </b-card-header>
                            <b-card-body>
                                <b-row>
                                    <b-col
                                        cols="12"
                                    >
                                        <hr class="mt-0 mb-2"/>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-for="(task, index) in tasks"
                                        :key="'task' + index"
                                    >
                                        <b-card no-body border-variant="light">
                                            <b-card-body>
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span
                                                            :class="{'text-primary': taskCompleteBtnClicked && selectedTask.TaskID === task.TaskID}"
                                                            @click="completeTask(task)"
                                                        >
                                                            <RtIcon 
                                                            :size="25" 
                                                            variant="solid" 
                                                            icon="check-circle mr-1 cursor-pointer ren-task-box"
                                                        />
                                                        </span>    
                                                        <span class="mr-1 text-primary">{{task.TaskSubject}}</span>
                                                        <span>{{task.date}}</span>
                                                    </div>
                                                    <div>
                                                        <span v-if="task.TaskDeliveryType !== 'once'" class="badge badge-success">
                                                            {{ i18nT('RÉPÉTER')}}
                                                        </span>
                                                        <span v-else-if="task.TaskActive === '1' && compareDate(task.date)" class="badge badge-primary">
                                                            {{ i18nT(`ACTIVE`)}}
                                                        </span>
                                                        <span v-else-if="task.TaskActive === '1' && !compareDate(task.date)" class="badge badge-warning">
                                                            {{ i18nT(`EN RETARD`)}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </b-card-body>
                                        </b-card>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-button class="w-100" variant="outline-primary">{{ i18nT(`Tout afficher`) }}</b-button>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </b-tab>
                    <b-tab
                        :title="i18nT(`Messages`)"
                    >
                    <template #title>
                        <div class="d-flex align-items-center ren-title">
                            <div class="mr-50">
                                <RtIcon :size="18" variant="light" icon="envelope"/>
                            </div>
                            <p class="m-0">
                                {{ i18nT(`Messages`)}}
                            </p>
                        </div>
                    </template>
                        <b-card
                            no-body
                        >
                            <b-card-header class="pb-0">
                                <h4>{{ i18nT(`Messages`) }}</h4>
                            </b-card-header>
                            <b-card-body>
                                <b-row>
                                    <b-col
                                        cols="12"
                                    >
                                        <hr class="mt-0 mb-2"/>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-for="(message, index) in messages"
                                        :key="'message' + index"
                                    >
                                        <b-card no-body border-variant="light">
                                            <b-card-body>
                                                <div class="d-flex align-items-center">
                                                    <RtIcon variant="light" icon="envelope mr-1 cursor-pointer" :size="25"/>
                                                    <b-link 
                                                        class="text-truncate w-50"
                                                        v-b-tooltip="message.MessageSubject"
                                                    >
                                                        {{ message.MessageSubject }}
                                                    </b-link>
                                                </div>
                                            </b-card-body>
                                        </b-card>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-button class="w-100" variant="outline-primary">{{ i18nT(`Tout afficher`) }}</b-button>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </b-tab>
                    <b-tab
                        :title="i18nT(`Documents`)"
                        v-if="documentsCount > 0"
                    >
                    <template #title>
                        <div class="d-flex align-items-center ren-title">
                            <div class="mr-50">
                                <RtIcon :size="18" variant="light" icon="briefcase"/>
                            </div>
                            <p class="m-0">
                                {{ i18nT(`Documents`)}}
                                <b-badge variant="primary">{{documentsCount}}</b-badge>
                            </p>
                        </div>
                    </template>
                    <document-tab @countChanged="updateDocumentCount"/>
                        <b-card
                            no-body
                            v-if="false"
                        >
                            <b-card-header class="pb-0">
                                <h4>{{ i18nT(`Documents`) }}</h4>
                            </b-card-header>
                            <b-card-body>
                                <b-row>
                                    <b-col
                                        cols="12"
                                    >
                                        <hr class="mt-0 mb-2"/>
                                    </b-col>
                                    <b-col 
                                        cols="12"
                                    >
                                        <div
                                            v-for="(item,index) in documents"
                                            :key="`document` + index"
                                            class="pb-1 pt-1"
                                            >
                                            <document-card :document="item" />
                                        </div>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-button class="w-100" variant="outline-primary">{{ i18nT(`Tout afficher`) }}</b-button>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </b-tab>
                    <b-tab
                        :title="i18nT(`Dossier locataire`)"
                    >
                    <template #title>
                        <div class="d-flex align-items-center ren-title">
                            <div class="mr-50">
                                <RtIcon :size="18" variant="light" icon="id-card-clip"/>
                            </div>
                            <p class="m-0">
                                {{ i18nT(`Dossier locataire`)}}
                            </p>
                        </div>
                    </template>
                        <b-card
                            no-body
                        >
                            <b-card-header class="pb-0">
                                <h4>{{ i18nT(`Dossier locataire`) }}</h4>
                            </b-card-header>
                            <b-card-body>
                                <b-row>
                                    <b-col
                                        cols="12"
                                    >
                                        <hr class="mt-0 mb-2"/>
                                    </b-col>
                                    <b-col
                                        cols="12"
                                        v-for="(file, index) in tenantFiles"
                                        :key="'file' + index"
                                    >
                                        <b-card no-body border-variant="light">
                                            <b-card-body>
                                                <div class="d-flex justify-content-between">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <RtIcon variant="light" icon="file-lines mr-1 cursor-pointer" :size="25"/>
                                                        <div>
                                                            <p class="my-0 text-primary">{{ file.DocumentName }}</p>
                                                            <p class="my-0">{{file.DocumentDescription ? file.DocumentDescription : i18nT(`AUTRE`)}}</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span>{{file.date}}</span>
                                                    </div>
                                                </div>
                                            </b-card-body>
                                        </b-card>
                                    </b-col>
                                    <b-col cols="12">
                                        <b-button class="w-100" variant="outline-primary">{{ i18nT(`Tout afficher`) }}</b-button>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </b-tab>
                    <b-tab
                        :title="i18nT(`Activité`)"
                    >
                    <template #title>
                        <div class="d-flex align-items-center ren-title">
                            <div class="mr-50">
                                <RtIcon :size="18" variant="light" icon="clock-rotate-left"/>
                            </div>
                            <p class="m-0">
                                {{ i18nT(`Activité`)}}
                            </p>
                        </div>
                    </template>
                        <b-card
                            no-body
                        >
                            <b-card-header class="pb-0">
                                <h4>{{ i18nT(`Historique`) }}</h4>
                            </b-card-header>
                            <b-card-body>
                                <b-row>
                                    <b-col
                                        cols="12"
                                    >
                                        <hr class="mt-0 mb-2"/>
                                        <app-timeline>
                                            <app-timeline-item
                                                v-for="(log, index) in logs"
                                                :key="index + 'log'"
                                                :title="log.ActionText"
                                                :subtitle="'Email: ' + log.LogUserEmail"
                                                :icon="getTimelineIcon(log.LogAction)"
                                                :variant="getTimelineVariant(log.LogAction)"
                                                :time="formatDate(log.CreatedAt)"
                                                :showDelete="false"
                                            />
                                        </app-timeline>
                                    </b-col>
                                </b-row>
                            </b-card-body>
                        </b-card>
                    </b-tab>
                </b-tabs>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {
    BAvatar,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BCard,
    BCardHeader,
    BCardBody,
    BFormTextarea,
    BTabs,
    BTab,
    BDropdown,
    BDropdownItem,
    BBadge,
    BLink,
    BForm,
    BFormRating,
    BFormSelect,
    BFormInput,
    BFormCheckbox,
    BModal,
    BProgress,
    VBTooltip,
} from 'bootstrap-vue'
import router from '@/router'
import { dictToSelectArray } from '@core/utils/utils'
import ContactCard from "@/views/components/contactCard/ContactCard.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import EllipsisDropdown from "@core/components/EllipsisDropdown.vue";
import DocumentCard from "@/views/common/Documents/DocumentCard.vue";
import useAuth from '@/auth/useAuth'
import moment from 'moment';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@/views/components/activityTimelineItem/ActivityTimelineItem.vue'
import DocumentTab from '@/views/components/tabs/DocumentTab/DocumentTab.vue'

export default {
    components: {
        DocumentCard,
        ContactCard,
        BAvatar,
        BButton,
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BCardHeader,
        BCardBody,
        BFormTextarea,
        BTabs,
        BTab,
        BDropdown,
        BDropdownItem,
        BBadge,
        BLink,
        BForm,
        BFormRating,
        BFormSelect,
        BFormInput,
        BFormCheckbox,
        BModal,
        BProgress,
        AppTimeline,
        AppTimelineItem,
        EllipsisDropdown,
        DocumentTab,
    },
    directives: {
      'b-tooltip': VBTooltip
    },
    data() {
        return {
            tenantDetails: null,
            rentedProperties: [],
            contacts: [],
            guarantors: [],
            tasks: [],
            documentsCount: 0,
            tenantFiles: [],
            messages: [],
            activationLink: '',
            dummyData: [
                {name: 'Test one', date: '12/05/2013', status: 'overdue', subTitle: 'SVB', TaskID: 1},
                {name: 'Test two', date: '16/10/1997', status: 'shared', subTitle: 'BMC', TaskID: 2},
                {name: 'Test three', date: '27/01/2000', status: 'private', subTitle: 'RGG', TaskID: 3},
                {name: 'Test four', date: '12/05/2013', status: 'overdue', subTitle: 'AXL', TaskID: 4},
            ],
            selectedTask: {},
            taskCompleteBtnClicked: false,
            logs: [],
            curTenantId: router.currentRoute.params.id
        }
    },
    created() {
        this.$http.get(`landlord/tenants/details?id=${this.curTenantId}`)
        .then(({data}) => {
            console.log('data: ', data.data)
            this.tenantDetails = data.data.tenant;
            this.tenantDetails.TenantBalance = parseInt(data.data.balance);
            this.tenantDetails.TenantIncome = parseInt(data.data['total_income']);
            this.rentedProperties = data.data.properties;
            this.contacts = data.data.contacts;
            this.guarantors = data.data.garants;
            this.tasks = data.data.tasks
            this.messages = data.data.messages
            this.documentsCount = data.data.documents
            this.tenantFiles = data.data.dossier_documents.slice(0, 7)
            this.activationLink = data.data.validate_sign_link
            this.logs = data.data.logs
        })

        // this.$http.get(`landlord/tenants/contacts?id=${router.currentRoute.params.id}`)
        // .then(({data}) => {
        //     this.contacts = data.data
        // })
        // .catch((err) => {
        //     // console.log('err: ', err)
        // })
    },
    methods: {
        compareDate(date) {

            const dateFormat = useAuth.getDateFormat() !== (null || 'null') ? useAuth.getDateFormat() : 'DD/MM/YYYY';

            const currentDate = moment().startOf('day')
            const inputDate = moment(date, dateFormat)

            console.log(inputDate.isBefore(currentDate))

            if(inputDate.isBefore(currentDate)) {
                return false
            } 
            else if (inputDate.isAfter(currentDate)) {
                return true
            }
            else {
                return true
            }
        },
        getTimelineIcon(action) {
            if (action === 'insert') {
			    return 'plus'
            }
            else if (action === 'update') {
			    return 'arrow-up-from-bracket'
            }
            else if (action === 'execArchive') {
			    return 'box-archive'
            }
            else if (action === 'execUnarchive') {
			    return 'box-archive'
            }
            else if (action === 'deleteRestore') {
                return 'arrows-rotate'
            }
            else if (action === 'deleteTrash') {
			    return 'trash'
            }
        },    
        getTimelineVariant(action) {
            if(action === 'insert' || action === 'update') {
                return 'primary'
            } else if (action === 'execUnarchive' || action === 'deleteRestore') {
                return 'info'
            }
            else if (action === 'execArchive') {
                return 'warning'
            }
            else {
                return 'danger'
            }
        },
        updateDocumentCount(newCount) {
            this.documentsCount = newCount
        },
        onEdit(){
            this.$router.push({ name: 'edit-tenant', params: { id: this.curTenantId } })
        },
        onDelete() {
                this.$swal({
                    title: this.i18nT(`Avertissement`),
                    text: this.i18nT(`Veuillez confirmer la suppression du locataire.`),
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonText: this.i18nT(`Annuler`),
                    confirmButtonText: this.i18nT(`Confirmer`),
                    customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-danger ml-1'
                    },
                    buttonsStyling: false
                }).then(result => {
                    if (result.value) {
                        console.log(result.value)
                        this.$http
                            .delete(`landlord/tenants?id=${this.tenantDetails.TenantID}`)
                            .then(() => {
                                this.$swal({
                                    icon: 'success',
                                    title: this.i18nT(`Succès!`),
                                    text: this.i18nT(`Supprimé avec succès!`),
                                    customClass: {
                                        confirmButton: 'btn btn-success'
                                    }
                                })
                                this.$router.push({ name: 'tenants' })
                            })
                    }
                })
            },
        statusLabel(status, invited, email) {
            if (!status && !invited && email) {
              return this.i18nT(`Inviter`);
            }
            if (!email) {
              return this.i18nT(`Pas d'email`);
            }
            if (invited && !status) {
              return this.i18nT(`En attente`);
            }
            if (status) {
              return this.i18nT(`Connecté`);
            }
            return '';
        },
        statusLabelClass(statusCode) {
            const statusClassDic = {
                1: 'info',
                2: 'primary',
                3: 'warning',
                4: 'secondary'
            }

            return statusClassDic[statusCode]
        },
        getTooltipLabel(statusCode) {

            const statusCodesDic = {
                1: this.i18nT(`Le locataire n'a pas d'email`),
                2: this.i18nT(`Envoyer une invitation`),
                3: this.i18nT(`Le locataire n'a pas encore accepté l'invitation`),
                4: this.i18nT(`Le locataire a créé son compte`)
            };

            return statusCodesDic[statusCode]
        },
        copyLink() {
            navigator.clipboard.writeText(this.activationLink)
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    icon: 'InfoIcon',
                    title: `Link copied`,
                    variant: 'primary'
                }
            })
        },
        openLink() {
            window.open(this.activationLink, '_blank')
        },
        formatDate(date) {
            let dateFormat = useAuth.getDateFormat() !== 'null' ?  useAuth.getDateFormat() : 'DD/MM/YYYY';
            dateFormat += ' HH:mm:ss'
            const formatted = moment(new Date(date)).format(dateFormat);
            
            return formatted;
        },
        completeTask(task) {

            // TODO Handle complete task when api is ready!
            this.taskCompleteBtnClicked = !this.taskCompleteBtnClicked

            this.selectedTask = task

            setTimeout(() => {
                if (!this.taskCompleteBtnClicked) return
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Status changed`,
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                    this.taskCompleteBtnClicked = false
            }, 2000)
        }
    }
}

</script>

<style>

.ren-info-cards {
    min-height: 120px;
}

.ren-task-box:hover {
    transform: translateY(-2.5px);
  transition: transform 0.3s ease-in-out;
}

.ren-title {
    transition: transform 0.3s ease-in-out;
    opacity: .7;
}

.ren-title:hover {
    opacity: 1;
    transform: translateY(-2.5px);
}

.nav-item > a[aria-selected="true"] .ren-title {
    opacity: 1;
}

.timeline-item {
    min-height: 80px;
}

</style>